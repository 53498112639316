const GenerateComponent = {
  functional: true,
  props: ['component'],
  render (createElement, context) {
    const component = context.props.component
    let data = {}
    if (['datepicker', 'timepicker'].includes(component.type)) {
      data = {
        style: {
          display: '-webkit-box',
        },
        ...context.data
      }
    } else {
      data = context.data
    }
    return createElement(component.componentName, data, context.children)
  }
}

export default GenerateComponent
