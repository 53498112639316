var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-design-main-container" },
    [
      _c("div", { staticClass: "operation" }, [
        _c(
          "div",
          [
            _c("v-select", {
              attrs: {
                label: "当前页面：",
                options: _vm.designContentOps,
                width: 200,
              },
              on: { change: _vm.designContentChange },
              model: {
                value: _vm.designContent,
                callback: function ($$v) {
                  _vm.designContent = $$v
                },
                expression: "designContent",
              },
            }),
            _vm.designContent
              ? _c("v-button", {
                  staticClass: "operation-item",
                  attrs: { type: "danger", text: "删除" },
                  on: { click: _vm.designContentDelete },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("v-button", {
              staticClass: "operation-item",
              attrs: { text: "生成代码", disabled: _vm.content.length < 1 },
              on: { click: _vm.generateCode },
            }),
            _c("v-button", {
              staticClass: "operation-item",
              attrs: { text: "清空", disabled: _vm.content.length < 1 },
              on: { click: _vm.clearComponent },
            }),
            _c("v-button", {
              staticClass: "operation-item",
              attrs: { text: "刷新", disabled: _vm.content.length < 1 },
              on: { click: _vm.refresh },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { key: _vm.contentKey, staticClass: "views" },
        [
          _vm.content.length ? _c("title-panel") : _vm._e(),
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "stage",
                on: { add: _vm.addComponent },
                model: {
                  value: _vm.content,
                  callback: function ($$v) {
                    _vm.content = $$v
                  },
                  expression: "content",
                },
              },
              "draggable",
              {
                group: { name: "component", put: _vm.canPut, pull: false },
                sort: false,
                ghostClass: "ghost-column",
              },
              false
            ),
            [
              _vm.content && _vm.content.length
                ? _vm._l(_vm.content, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "content" },
                      [
                        item.type === "list"
                          ? [_c("list-page", { attrs: { item: item } })]
                          : _vm._e(),
                        item.type === "form"
                          ? [_c("form-page", { attrs: { item: item } })]
                          : _vm._e(),
                      ],
                      2
                    )
                  })
                : [
                    _c("div", { staticClass: "empty" }, [
                      _vm._v("请先从左侧拖拽页面组件到此区域"),
                    ]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "生成代码",
            width: "60%",
            visible: _vm.generateCodeDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.generateCodeDialogVisible = $event
            },
          },
        },
        [
          _vm.generateCodeDialogVisible
            ? _c("code-editor", {
                attrs: { tabs: _vm.tabs, defaultTab: _vm.defaultTab },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "copy-code" },
            [
              _c("v-button", {
                attrs: { text: "开始构建" },
                on: { click: _vm.onBuild },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("api-template"),
      _c("map-template"),
      _c("list-page-template"),
      _c("form-page-template"),
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "design-dialog",
            title: "初始化",
            visible: _vm.dialogVisible,
            "show-close": false,
            "close-on-click-modal": false,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("v-select", {
            staticStyle: { "margin-right": "20px" },
            attrs: {
              options: _vm.centerNameOps,
              label: "所属中心",
              width: 200,
            },
            model: {
              value: _vm.centerName,
              callback: function ($$v) {
                _vm.centerName = $$v
              },
              expression: "centerName",
            },
          }),
          _c("v-input", {
            staticStyle: { "margin-right": "20px" },
            attrs: { label: "所属模块", width: 200 },
            model: {
              value: _vm.routerName,
              callback: function ($$v) {
                _vm.routerName = $$v
              },
              expression: "routerName",
            },
          }),
          _c("v-button", {
            attrs: {
              text: "确定",
              disabled: !(_vm.centerName && _vm.routerName),
            },
            on: { click: _vm.init },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }