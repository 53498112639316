var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "code-editor-container" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        _vm._l(_vm.tabs, function (tabItem, tabIndex) {
          return _c(
            "el-tab-pane",
            {
              key: tabIndex,
              attrs: { label: tabItem.label, name: tabItem.name },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.visible,
                      expression: "visible",
                    },
                  ],
                  staticClass: "code-editor",
                  attrs: { id: "code_editor_" + tabIndex },
                },
                [_vm._v("\n        " + _vm._s(tabItem.code) + "\n      ")]
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }