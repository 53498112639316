var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-design-config-container" }, [
    _c(
      "div",
      { staticClass: "title" },
      _vm._l(_vm.tabs, function (item, index) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "title-item",
            class: { "title-item-active": _vm.active == item.id },
            on: {
              click: function ($event) {
                return _vm.setActive(index)
              },
            },
          },
          [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "component-config-list" },
      [
        _vm.active === 0
          ? [
              _vm.currentPage && Object.keys(_vm.currentPage).length
                ? [
                    _vm.currentPage.type === "list"
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("组件名称")]
                              ),
                              _c("v-input", {
                                model: {
                                  value: _vm.currentPage.options.componentName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "componentName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.componentName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("查询接口")]
                              ),
                              _c("v-select", {
                                staticClass: "component-config-control",
                                attrs: { options: _vm.baseUrlOps },
                                model: {
                                  value: _vm.currentPage.options.baseUrl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "baseUrl",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.baseUrl",
                                },
                              }),
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入接口地址",
                                  maxlength: 200,
                                },
                                model: {
                                  value: _vm.currentPage.options.api,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "api",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.api",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("列表项")]
                              ),
                              _vm._l(
                                _vm.currentPage.options.props.headers,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "component-config-item-options",
                                    },
                                    [
                                      _c("v-input", {
                                        model: {
                                          value: item.label,
                                          callback: function ($$v) {
                                            _vm.$set(item, "label", $$v)
                                          },
                                          expression: "item.label",
                                        },
                                      }),
                                      _c("v-input", {
                                        model: {
                                          value: item.prop,
                                          callback: function ($$v) {
                                            _vm.$set(item, "prop", $$v)
                                          },
                                          expression: "item.prop",
                                        },
                                      }),
                                      _c("v-button", {
                                        attrs: { type: "text", text: "配置" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.configHeader(item)
                                          },
                                        },
                                      }),
                                      _c("v-button", {
                                        attrs: { type: "text", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeHeader(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c("v-button", {
                                attrs: { type: "text", text: "添加" },
                                on: { click: _vm.addHeader },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("操作按钮")]
                              ),
                              _vm._l(
                                _vm.currentPage.options.slots.operateSlot,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "component-config-item-options",
                                    },
                                    [
                                      _c("v-input", {
                                        model: {
                                          value: _vm.findProp(
                                            item.props,
                                            "text"
                                          ).value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.findProp(item.props, "text"),
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "findProp(item.props, 'text').value",
                                        },
                                      }),
                                      _c("v-button", {
                                        attrs: { type: "text", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeOperation(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c("v-button", {
                                attrs: { type: "text", text: "添加" },
                                on: { click: _vm.addOperation },
                              }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("显示多选列")]
                              ),
                              _c("el-switch", {
                                model: {
                                  value:
                                    _vm.currentPage.options.props.isMultiSelect,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options.props,
                                      "isMultiSelect",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.props.isMultiSelect",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("显示操作列")]
                              ),
                              _c("el-switch", {
                                model: {
                                  value:
                                    _vm.currentPage.options.props
                                      .hasOperateColumn,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options.props,
                                      "hasOperateColumn",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.props.hasOperateColumn",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                "custom-class": "config-header-dialog",
                                title: "配置列表项",
                                visible: _vm.configHeaderDialogVisible,
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.configHeaderDialogVisible = $event
                                },
                              },
                            },
                            [
                              _vm.configHeaderDialogVisible
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "config-header-dialog-content",
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "选择映射关系",
                                          options: _vm.mapOps,
                                          clearable: "",
                                        },
                                        on: { change: _vm.mapChange },
                                        model: {
                                          value: _vm.map,
                                          callback: function ($$v) {
                                            _vm.map = $$v
                                          },
                                          expression: "map",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm.currentPage.type === "form"
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("组件名称")]
                              ),
                              _c("v-input", {
                                model: {
                                  value: _vm.currentPage.options.componentName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "componentName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.componentName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("详情查询接口")]
                              ),
                              _c("v-select", {
                                staticClass: "component-config-control",
                                attrs: { options: _vm.baseUrlOps },
                                model: {
                                  value: _vm.currentPage.options.queryBaseURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "queryBaseURL",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.queryBaseURL",
                                },
                              }),
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入接口地址",
                                  maxlength: 200,
                                },
                                model: {
                                  value: _vm.currentPage.options.queryURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "queryURL",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.queryURL",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("新增接口")]
                              ),
                              _c("v-select", {
                                staticClass: "component-config-control",
                                attrs: { options: _vm.baseUrlOps },
                                model: {
                                  value: _vm.currentPage.options.createBaseURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "createBaseURL",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.createBaseURL",
                                },
                              }),
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入接口地址",
                                  maxlength: 200,
                                },
                                model: {
                                  value: _vm.currentPage.options.createURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "createURL",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.createURL",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("编辑接口")]
                              ),
                              _c("v-select", {
                                staticClass: "component-config-control",
                                attrs: { options: _vm.baseUrlOps },
                                model: {
                                  value: _vm.currentPage.options.updateBaseURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "updateBaseURL",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentPage.options.updateBaseURL",
                                },
                              }),
                              _c("v-input", {
                                attrs: {
                                  placeholder: "请输入接口地址",
                                  maxlength: 200,
                                },
                                model: {
                                  value: _vm.currentPage.options.updateURL,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "updateURL",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.updateURL",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    Object.keys(_vm.currentPage.options).includes("hasStyle")
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("style标签")]
                              ),
                              _c("el-switch", {
                                model: {
                                  value: _vm.currentPage.options.hasStyle,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentPage.options,
                                      "hasStyle",
                                      $$v
                                    )
                                  },
                                  expression: "currentPage.options.hasStyle",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _c("div", { staticClass: "empty" }, [
                      _vm._v("暂无页面属性"),
                    ]),
                  ],
            ]
          : _vm.active === 1
          ? [
              _vm.currentComponent && Object.keys(_vm.currentComponent).length
                ? [
                    Object.keys(_vm.currentComponent).includes("model")
                      ? _vm._l(
                          _vm.currentComponent.model,
                          function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "component-config-item",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "component-config-item-name" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c("v-input", {
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.currentPage.type === "form" &&
                    Object.keys(_vm.currentComponent).includes("formItemLabel")
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("字段描述")]
                              ),
                              _c("v-input", {
                                model: {
                                  value: _vm.currentComponent.formItemLabel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentComponent,
                                      "formItemLabel",
                                      $$v
                                    )
                                  },
                                  expression: "currentComponent.formItemLabel",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _vm._l(_vm.currentComponent.props, function (item) {
                      return [
                        item.show
                          ? [
                              _c(
                                "div",
                                {
                                  key: item.key,
                                  staticClass: "component-config-item",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "component-config-item-name",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                  item.type === "number"
                                    ? [
                                        _c("v-input-number", {
                                          attrs: {
                                            controls: true,
                                            min: item.min,
                                            max: item.max,
                                          },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ]
                                    : item.type === "select"
                                    ? [
                                        _c("v-select", {
                                          attrs: { options: item.options },
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ]
                                    : item.type === "boolean"
                                    ? [
                                        _c("el-switch", {
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ]
                                    : [
                                        _c("v-input", {
                                          model: {
                                            value: item.value,
                                            callback: function ($$v) {
                                              _vm.$set(item, "value", $$v)
                                            },
                                            expression: "item.value",
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    _vm.currentComponent.type === "button"
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("点击事件")]
                              ),
                              _c("v-input", {
                                attrs: { placeholder: "方法名" },
                                model: {
                                  value:
                                    _vm.currentComponent.events.click
                                      .methodName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentComponent.events.click,
                                      "methodName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentComponent.events.click.methodName",
                                },
                              }),
                              _c("v-input", {
                                attrs: { placeholder: "参数" },
                                model: {
                                  value:
                                    _vm.currentComponent.events.click
                                      .methodParams,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentComponent.events.click,
                                      "methodParams",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "currentComponent.events.click.methodParams",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : ["upload", "fileUpload"].includes(
                          _vm.currentComponent.type
                        )
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("模块")]
                              ),
                              _c("v-input", {
                                model: {
                                  value: _vm.currentComponent.module,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentComponent,
                                      "module",
                                      $$v
                                    )
                                  },
                                  expression: "currentComponent.module",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    Object.keys(_vm.currentComponent).includes("options")
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("选项")]
                              ),
                              _vm._l(
                                _vm.currentComponent.options,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "component-config-item-options",
                                    },
                                    [
                                      _c("v-input", {
                                        model: {
                                          value: item.text,
                                          callback: function ($$v) {
                                            _vm.$set(item, "text", $$v)
                                          },
                                          expression: "item.text",
                                        },
                                      }),
                                      _c("v-input", {
                                        model: {
                                          value: item.value,
                                          callback: function ($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value",
                                        },
                                      }),
                                      _c("v-button", {
                                        attrs: { type: "text", text: "删除" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeOptions(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c("v-button", {
                                attrs: { type: "text", text: "添加" },
                                on: { click: _vm.addOptions },
                              }),
                            ],
                            2
                          ),
                        ]
                      : _vm._e(),
                    _vm.currentPage.type === "form" &&
                    Object.keys(_vm.currentComponent).includes("rule")
                      ? [
                          _c(
                            "div",
                            { staticClass: "component-config-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item-name" },
                                [_vm._v("是否必填")]
                              ),
                              _c("el-switch", {
                                model: {
                                  value: _vm.currentComponent.rule.required,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.currentComponent.rule,
                                      "required",
                                      $$v
                                    )
                                  },
                                  expression: "currentComponent.rule.required",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentComponent.rule.required,
                                  expression: "currentComponent.rule.required",
                                },
                              ],
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "component-config-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "component-config-item-name",
                                    },
                                    [_vm._v("提示信息")]
                                  ),
                                  _c("v-input", {
                                    model: {
                                      value: _vm.currentComponent.rule.message,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentComponent.rule,
                                          "message",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentComponent.rule.message",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "component-config-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "component-config-item-name",
                                    },
                                    [_vm._v("触发方式")]
                                  ),
                                  _c("v-input", {
                                    model: {
                                      value: _vm.currentComponent.rule.trigger,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.currentComponent.rule,
                                          "trigger",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "currentComponent.rule.trigger",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _c("div", { staticClass: "empty" }, [
                      _vm._v("暂无组件属性"),
                    ]),
                  ],
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }