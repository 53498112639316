var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-page-container" },
    [
      _c("list", {
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.item.options.props.headers,
          isMultiSelect: _vm.item.options.props.isMultiSelect,
          hasOperateColumn: _vm.item.options.props.hasOperateColumn,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "slot slot-header",
                      on: { add: _vm.headerSlotAdd },
                      model: {
                        value: _vm.item.options.slots.headerSlot,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.options.slots, "headerSlot", $$v)
                        },
                        expression: "item.options.slots.headerSlot",
                      },
                    },
                    "draggable",
                    {
                      group: { name: "component", put: _vm.canPutHeaderSlot },
                      ghostClass: "ghost-row",
                      animation: 200,
                    },
                    false
                  ),
                  [
                    _vm._l(
                      _vm.item.options.slots.headerSlot,
                      function (component) {
                        return [
                          _c(
                            "generate-component",
                            _vm._b(
                              {
                                key: component.key,
                                staticClass: "slot-item",
                                class: {
                                  "slot-item-active":
                                    _vm.activeKey == component.key,
                                },
                                attrs: { component: component },
                                nativeOn: {
                                  "!click": function ($event) {
                                    return _vm.clickHandler(component)
                                  },
                                },
                              },
                              "generate-component",
                              _vm.getProps(component),
                              false
                            )
                          ),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "slot slot-search",
                      on: { add: _vm.searchSlotAdd },
                      model: {
                        value: _vm.item.options.slots.searchSlot,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.options.slots, "searchSlot", $$v)
                        },
                        expression: "item.options.slots.searchSlot",
                      },
                    },
                    "draggable",
                    {
                      group: { name: "component", put: _vm.canPutSearchSlot },
                      ghostClass: "ghost-row",
                      animation: 200,
                    },
                    false
                  ),
                  [
                    _vm._l(
                      _vm.item.options.slots.searchSlot,
                      function (component) {
                        return [
                          _c(
                            "generate-component",
                            _vm._b(
                              {
                                key: component.key,
                                staticClass: "slot-item",
                                class: {
                                  "slot-item-active":
                                    _vm.activeKey == component.key,
                                },
                                attrs: { component: component },
                                nativeOn: {
                                  "!click": function ($event) {
                                    return _vm.clickHandler(component)
                                  },
                                },
                                model: {
                                  value: undefined,
                                  callback: function ($$v) {
                                    undefined = $$v
                                  },
                                  expression: "undefined",
                                },
                              },
                              "generate-component",
                              _vm.getProps(component),
                              false
                            )
                          ),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function () {
              return [
                _vm._l(
                  _vm.item.options.slots.operateSlot,
                  function (component) {
                    return [
                      _c(
                        "generate-component",
                        _vm._b(
                          {
                            key: component.key,
                            staticClass: "slot-item",
                            class: {
                              "slot-item-active":
                                _vm.activeKey == component.key,
                            },
                            attrs: { component: component },
                            nativeOn: {
                              "!click": function ($event) {
                                return _vm.clickHandler(component)
                              },
                            },
                          },
                          "generate-component",
                          _vm.getProps(component),
                          false
                        )
                      ),
                    ]
                  }
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "batchSlot",
            fn: function () {
              return [
                _c(
                  "draggable",
                  _vm._b(
                    {
                      staticClass: "slot slot-batch",
                      on: { add: _vm.batchSlotAdd },
                      model: {
                        value: _vm.item.options.slots.batchSlot,
                        callback: function ($$v) {
                          _vm.$set(_vm.item.options.slots, "batchSlot", $$v)
                        },
                        expression: "item.options.slots.batchSlot",
                      },
                    },
                    "draggable",
                    {
                      group: { name: "component", put: _vm.canPutBatchSlot },
                      ghostClass: "ghost-row",
                      animation: 200,
                    },
                    false
                  ),
                  [
                    _vm._l(
                      _vm.item.options.slots.batchSlot,
                      function (component) {
                        return [
                          _c(
                            "generate-component",
                            _vm._b(
                              {
                                key: component.key,
                                staticClass: "slot-item",
                                class: {
                                  "slot-item-active":
                                    _vm.activeKey == component.key,
                                },
                                attrs: { component: component },
                                nativeOn: {
                                  "!click": function ($event) {
                                    return _vm.clickHandler(component)
                                  },
                                },
                              },
                              "generate-component",
                              _vm.getProps(component),
                              false
                            )
                          ),
                        ]
                      }
                    ),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }