import { findProp } from './utils'

const componentGroup = [
  {
    type: 'page',
    title: '页面组件',
    list: [
      {
        type: 'list',
        componentName: 'list',
        componentNameImport: 'List',
        name: '列表',
        options: {
          componentName: 'List',
          baseUrl: 'controlBaseURL',
          api: 'formInfo/pageList',
          props: {
            searchUrl: '',
            isMultiSelect: false,
            hasOperateColumn: true,
            headers: [
              {
                prop: 'name',
                label: '名称'
              }
            ]
          },
          slots: {
            headerSlot: [],
            searchSlot: [],
            batchSlot: [],
            operateSlot: []
          },
          supportTypes: {
            headerSlot: ['button'],
            searchSlot: ['input', 'select', 'datepicker', 'timepicker'],
            batchSlot: ['button']
          }
        }
      },
      {
        type: 'form',
        componentName: 'form-panel',
        componentNameImport: 'FormPanel',
        name: '表单',
        options: {
          componentName: 'Form',
          submitMethod: 'POST',
          createBaseURL: 'controlBaseURL',
          createURL: '',
          updateBaseURL: 'controlBaseURL',
          updateURL: '',
          queryBaseURL: 'controlBaseURL',
          queryURL: '',
          hasStyle: false,
          props: {

          },
          slots: {
            defaultSlot: []
          },
          supportTypes: {
            defaultSlot: ['input', 'inputNumber', 'select', 'textarea', 'radio', 'checkbox', 'ueditor', 'upload', 'fileUpload']
          }
        }
      }
    ]
  },
  {
    type: 'common',
    title: '常用组件',
    list: [
      {
        type: 'button',
        componentName: 'v-button',
        componentNameImport: 'vButton',
        name: '新增按钮',
        props: [
          {
            key: 'type',
            value: undefined,
            show: false
          },
          {
            key: 'text',
            value: '新增',
            type: 'input',
            name: '按钮名称',
            show: true
          },
          {
            key: 'permission',
            value: 'add',
            type: 'input',
            name: '权限key',
            show: true
          }
        ],
        events: {
          click: {
            methodName: 'create',
            methodParams: '',
          }
        }
      }
    ]
  },
  {
    type: 'control',
    title: '基础组件',
    list: [
      {
        type: 'button',
        componentName: 'v-button',
        componentNameImport: 'vButton',
        name: '按钮',
        props: [
          {
            key: 'type',
            value: undefined,
            show: false
          },
          {
            key: 'text',
            value: '',
            type: 'input',
            name: '按钮名称',
            show: true
          },
          {
            key: 'permission',
            value: '',
            type: 'input',
            name: '权限key',
            show: true
          }
        ],
        events: {
          click: {
            methodName: '',
            methodParams: '',
          }
        }
      },
      {
        type: 'select',
        componentName: 'v-select',
        componentNameImport: 'vSelect',
        name: '下拉选择框',
        getDefaultValue: () => {
          return undefined
        },
        options: [
          {
            text: '选项1',
            value: 1
          },
          {
            text: '选项2',
            value: 2
          }
        ],
        model: [
          {
            mode: 1,
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'label',
            value: '下拉选择框',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'placeholder',
            value: '',
            type: 'input',
            name: '占位内容',
            show: true
          },
          {
            key: 'options',
            prefix: ':',
            value: '',
            show: false
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请选择',
          trigger: 'change'
        },
        formItemLabel: '下拉选择框'
      },
      {
        type: 'input',
        componentName: 'v-input',
        componentNameImport: 'vInput',
        name: '单行文本',
        getDefaultValue: () => {
          return ''
        },
        model: [
          {
            mode: 1,
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'label',
            value: '单行文本',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'placeholder',
            value: '',
            type: 'input',
            name: '占位内容',
            show: true
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请输入',
          trigger: 'change'
        },
        formItemLabel: '单行文本'
      },
      {
        type: 'textarea',
        componentName: 'v-textarea',
        componentNameImport: 'vTextarea',
        name: '多行文本',
        getDefaultValue: () => {
          return ''
        },
        model: [
          {
            mode: 1,
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'label',
            value: '多行文本',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'placeholder',
            value: '',
            type: 'input',
            name: '占位内容',
            show: true
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请输入',
          trigger: 'change'
        },
        formItemLabel: '多行文本'
      },
      {
        type: 'datepicker',
        componentName: 'v-datepicker',
        componentNameImport: 'vDatepicker',
        name: '日期选择器',
        getDefaultValue: () => {
          return ''
        },
        model: [
          {
            mode: 2,
            key: 'startTime',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名（开始日期）',
          },
          {
            mode: 2,
            key: 'endTime',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名（结束日期）',
          }
        ],
        props: [
          {
            key: 'type',
            value: undefined,
            type: 'select',
            options: [
              {
                text: 'YY-MM-DD',
                value: undefined
              },
              {
                text: 'YY-MM-DD HH:mm',
                value: 'rangedatetimer'
              }
            ],
            name: '类型',
            show: true
          },
          {
            key: 'label',
            value: '日期选择器',
            type: 'input',
            name: '描述',
            show: true
          }
        ],
        events: {}
      },
      {
        type: 'timepicker',
        componentName: 'v-timepicker',
        componentNameImport: 'vTimepicker',
        name: '时间选择器',
        getDefaultValue: () => {
          return ''
        },
        model: [
          {
            mode: 2,
            key: 'startTime',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名（开始时间）',
          },
          {
            mode: 2,
            key: 'endTime',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名（结束时间）',
          }
        ],
        props: [
          {
            key: 'label',
            value: '时间选择器',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'isRange',
            prefix: ':',
            value: true,
            show: false
          },
          {
            key: 'rangeSeparator',
            value: '至',
            show: false
          }
        ],
        events: {}
      },
      {
        type: 'radio',
        componentName: 'checkbox-plus',
        componentNameImport: 'CheckboxPlus',
        name: '单选框',
        getDefaultValue: () => {
          return undefined
        },
        options: [
          {
            text: '选项1',
            value: 1
          },
          {
            text: '选项2',
            value: 2
          }
        ],
        model: [
          {
            mode: 2,
            key: 'value',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'type',
            value: 'radio',
            show: false
          },
          {
            key: 'label',
            value: '单选框',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'options',
            prefix: ':',
            value: '',
            show: false
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请选择',
          trigger: 'change'
        },
        formItemLabel: '单选框'
      },
      {
        type: 'checkbox',
        componentName: 'checkbox-plus',
        componentNameImport: 'CheckboxPlus',
        name: '多选框',
        getDefaultValue: () => {
          return []
        },
        options: [
          {
            text: '选项1',
            value: 1
          },
          {
            text: '选项2',
            value: 2
          }
        ],
        model: [
          {
            mode: 2,
            key: 'value',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'label',
            value: '多选框',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'type',
            value: 'default',
            type: 'select',
            options: [
              {
                text: '普通checkbox样式',
                value: 'default'
              },
              {
                text: '按钮样式',
                value: undefined
              }
            ],
            name: '样式类型',
            show: true
          },
          {
            key: 'mode',
            value: 'checkbox-plus',
            show: false
          },
          {
            key: 'options',
            prefix: ':',
            value: '',
            show: false
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请选择',
          trigger: 'change'
        },
        formItemLabel: '多选框'
      },
      {
        type: 'inputNumber',
        componentName: 'v-input-number',
        componentNameImport: 'vInputNumber',
        name: '计数器',
        getDefaultValue: () => {
          return undefined
        },
        model: [
          {
            mode: 1,
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'label',
            value: '计数器',
            type: 'input',
            name: '描述',
            show: true
          },
          {
            key: 'placeholder',
            value: '',
            type: 'input',
            name: '占位内容',
            show: true
          },
          {
            key: 'min',
            prefix: ':',
            value: undefined,
            max: Number.POSITIVE_INFINITY,
            min: Number.NEGATIVE_INFINITY,
            type: 'number',
            name: '最小值',
            show: true
          },
          {
            key: 'max',
            prefix: ':',
            value: undefined,
            max: Number.POSITIVE_INFINITY,
            min: Number.NEGATIVE_INFINITY,
            type: 'number',
            name: '最大值',
            show: true
          },
          {
            key: 'controls',
            prefix: ':',
            value: true,
            type: 'boolean',
            name: '控制按钮',
            ignoreCondition: function (param) {
              return param.value === false
            },
            show: true
          },
          {
            key: 'controls-position',
            value: undefined,
            type: 'select',
            options: [
              {
                text: '默认',
                value: undefined
              },
              {
                text: '右侧',
                value: 'right'
              }
            ],
            name: '控制按钮位置',
            show: true
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        formItemLabel: '计数器'
      },
      {
        type: 'ueditor',
        componentName: 'v-ueditor',
        componentNameImport: 'vUeditor',
        name: '富文本编辑器',
        getDefaultValue: () => {
          return ''
        },
        model: [
          {
            mode: 1,
            value: '',
            name: '字段名',
          }
        ],
        props: [],
        events: {},
        rule: {
          required: true,
          message: '请输入',
          trigger: 'change'
        },
        formItemLabel: '富文本编辑器'
      },
      {
        type: 'upload',
        componentName: 'v-uploader',
        componentNameImport: 'vUploader',
        name: '图片上传',
        module: 'icon',
        getDefaultValue: function (param) {
          const limitValue = findProp(param.props, 'limit').value
          return limitValue === 1 ? '' : []
        },
        model: [
          {
            mode: 2,
            key: 'imgUrls',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'action',
            prefix: ':',
            value: 'UploadURL',
            show: false
          },
          {
            key: 'limit',
            prefix: ':',
            value: 1,
            type: 'number',
            min: 1,
            name: '最大上传数',
            ignoreCondition: function (param) {
              return param.value === 1
            },
            show: true
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请选择',
          trigger: 'change'
        },
        formItemLabel: '图片上传'
      },
      {
        type: 'fileUpload',
        componentName: 'v-uploader',
        componentNameImport: 'vUploader',
        name: '文件上传',
        module: 'show',
        getDefaultValue: () => {
          return []
        },
        model: [
          {
            mode: 2,
            key: 'fileList',
            prefix: ':',
            suffix: '.sync',
            value: '',
            name: '字段名',
          }
        ],
        props: [
          {
            key: 'action',
            prefix: ':',
            value: 'UploadURL',
            show: false
          },
          {
            key: 'limit',
            prefix: ':',
            value: 1,
            type: 'number',
            min: 1,
            name: '最大上传数',
            ignoreCondition: function (param) {
              return param.value === 1
            },
            show: true
          },
          {
            key: 'onlyImage',
            prefix: ':',
            value: false,
            show: false
          }
        ],
        events: {},
        rule: {
          required: true,
          message: '请选择',
          trigger: 'change'
        },
        formItemLabel: '文件上传'
      }
    ]
  }
  // {
  //   type: 'bussiness',
  //   title: '业务组件',
  //   list: []
  // }
]

const operationButton = {
  type: 'button',
  componentName: 'v-button',
  componentNameImport: 'vButton',
  name: '编辑按钮',
  props: [
    {
      key: 'type',
      value: 'text',
      show: false
    },
    {
      key: 'text',
      value: '编辑',
      type: 'input',
      name: '按钮名称',
      show: true
    },
    {
      key: 'permission',
      value: 'update',
      type: 'input',
      name: '权限key',
      show: true
    }
  ],
  events: {
    click: {
      methodName: 'update',
      methodParams: 'scope.row',
    }
  }
}

export {
  componentGroup,
  operationButton
}
