<template>
  <div class="form-page-container">

    <form-panel>

      <template #headerSlot>
        <div />
      </template>

      <draggable
        class="slot slot-default"
        v-model="item.options.slots.defaultSlot"
        v-bind="{ group: { name: 'component', put: canPutDefaultSlot }, ghostClass: 'ghost-column', animation: 200 }"
        @add="defaultSlotAdd"
      >
        <template v-for="component in item.options.slots.defaultSlot">

          <div
            class="slot-item"
            :class="{ 'slot-item-active': activeKey == component.key }"
            :key="component.key"
            @click.stop="clickHandler(component)"
          >
            <el-form-item :label="component.formItemLabel">
              <generate-component :component="component" />
            </el-form-item>
          </div>

        </template>
      </draggable>
    </form-panel>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Draggable from 'vuedraggable'
import GenerateComponent from './generate-component'

export default {
  name: 'form-page',
  components: {
    Draggable,
    GenerateComponent
  },
  inject: ['setCurrentComponent', 'getCurrentComponent'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    activeKey () {
      const currentComponent = this.getCurrentComponent()
      return currentComponent ? currentComponent.key : ''
    }
  },
  methods: {
    findProp (list, key) {
      let prop = {}
      if (list && list.length) {
        prop = list.find(item => {
          return item.key === key
        }) || {}
      }
      return prop
    },
    clickHandler (component) {
      this.setCurrentComponent(component)
    },
    canPutDefaultSlot (a, b, c) {
      const type = c.getAttribute("data-type")
      const supportTypes = this.item.options.supportTypes.defaultSlot
      if (supportTypes.includes(type)) {
        return true
      }
      return false
    },
    defaultSlotAdd (e) {
      const { newIndex } = e
      const defaultSlot = this.item.options.slots.defaultSlot
      const key = new Date().getTime().toString()
      const component = cloneDeep({
        ...defaultSlot[newIndex],
        key
      })
      const labelProp = this.findProp(component.props, 'label')
      if (component.formItemLabel !== undefined) {
        labelProp.value = ''
        labelProp.show = false
      }
      this.$set(defaultSlot, newIndex, component)
      this.setCurrentComponent(component)
    },
  }
}
</script>

<style lang="scss" scoped>
.form-page-container {
  height: 100%;

  .slot {
    border: 1px dashed #999;

    .slot-item {
      padding-top: 20px;

      &.slot-item-active {
        border: 2px solid #409EFF;
      }
    }
  }

  .slot-default {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);
    height: 800px;
    overflow: auto;
  }
}
</style>
