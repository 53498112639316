<template>
  <div class="list-page-container">

    <list
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="item.options.props.headers"
      :isMultiSelect="item.options.props.isMultiSelect"
      :hasOperateColumn="item.options.props.hasOperateColumn"
    >

      <template #headerSlot>
        <draggable
          class="slot slot-header"
          v-model="item.options.slots.headerSlot"
          v-bind="{ group: { name: 'component', put: canPutHeaderSlot }, ghostClass: 'ghost-row', animation: 200 }"
          @add="headerSlotAdd"
        >
          <template v-for="component in item.options.slots.headerSlot">
            <generate-component class="slot-item" :class="{ 'slot-item-active': activeKey == component.key }" :component="component" v-bind="getProps(component)" :key="component.key" @click.native.capture="clickHandler(component)" />
          </template>
        </draggable>
      </template>

      <template #searchSlot>
        <draggable
          class="slot slot-search"
          v-model="item.options.slots.searchSlot"
          v-bind="{ group: { name: 'component', put: canPutSearchSlot }, ghostClass: 'ghost-row', animation: 200 }"
          @add="searchSlotAdd"
        >
          <template v-for="component in item.options.slots.searchSlot">
            <generate-component class="slot-item" :class="{ 'slot-item-active': activeKey == component.key }" v-model="undefined" :component="component" v-bind="getProps(component)" :key="component.key" @click.native.capture="clickHandler(component)" />
          </template>
        </draggable>
      </template>

      <template #operateSlot>
        <template v-for="component in item.options.slots.operateSlot">
          <generate-component class="slot-item" :class="{ 'slot-item-active': activeKey == component.key }" :component="component" v-bind="getProps(component)" :key="component.key" @click.native.capture="clickHandler(component)" />
        </template>
      </template>

      <template #batchSlot>
        <draggable
          class="slot slot-batch"
          v-model="item.options.slots.batchSlot"
          v-bind="{ group: { name: 'component', put: canPutBatchSlot }, ghostClass: 'ghost-row', animation: 200 }"
          @add="batchSlotAdd"
        >
          <template v-for="component in item.options.slots.batchSlot">
            <generate-component class="slot-item" :class="{ 'slot-item-active': activeKey == component.key }" :component="component" v-bind="getProps(component)" :key="component.key" @click.native.capture="clickHandler(component)" />
          </template>
        </draggable>
      </template>

    </list>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Draggable from 'vuedraggable'
import GenerateComponent from '../common/generateComponent'

export default {
  name: 'list-page',
  components: {
    Draggable,
    GenerateComponent
  },
  inject: ['setCurrentComponent', 'getCurrentComponent'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      searchList: [],
      searchParams: {}
    }
  },
  computed: {
    searchUrl () {
      return `${API_CONFIG[this.item.options.baseUrl]}${this.item.options.api}`
    },
    activeKey () {
      const currentComponent = this.getCurrentComponent()
      return currentComponent ? currentComponent.key : ''
    }
  },
  methods: {
    getProps (component) {
      const props = {}
      if (component && component.props && component.props.length) {
        component.props.forEach(prop => {
          props[prop.key] = prop.value
        })
      }
      if (component.type === 'button') {
        props['permission'] = true
      } else if (component.type === 'select') {
        props['options'] = undefined
      } else if (component.type === 'datepicker') {
        props['startTime'] = undefined
        props['endTime'] = undefined
      }
      return props
    },
    clickHandler (component) {
      this.setCurrentComponent(component)
    },
    canPutHeaderSlot (a, b, c) {
      const type = c.getAttribute("data-type")
      const supportTypes = this.item.options.supportTypes.headerSlot
      if (supportTypes.includes(type)) {
        return true
      }
      return false
    },
    canPutSearchSlot (a, b, c) {
      const type = c.getAttribute("data-type")
      const supportTypes = this.item.options.supportTypes.searchSlot
      if (supportTypes.includes(type)) {
        return true
      }
      return false
    },
    canPutBatchSlot (a, b, c) {
      const type = c.getAttribute("data-type")
      const supportTypes = this.item.options.supportTypes.batchSlot
      if (supportTypes.includes(type)) {
        return true
      }
      return false
    },
    headerSlotAdd (e) {
      const { newIndex } = e
      const headerSlot = this.item.options.slots.headerSlot
      const key = new Date().getTime().toString()
      const component = cloneDeep({
        ...headerSlot[newIndex],
        key
      })
      this.$set(headerSlot, newIndex, component)
      this.setCurrentComponent(component)
    },
    searchSlotAdd (e) {
      const { newIndex } = e
      const searchSlot = this.item.options.slots.searchSlot
      const key = new Date().getTime().toString()
      const component = cloneDeep({
        ...searchSlot[newIndex],
        key
      })
      this.$set(searchSlot, newIndex, component)
      this.setCurrentComponent(component)
    },
    batchSlotAdd (e) {
      const { newIndex } = e
      const batchSlot = this.item.options.slots.batchSlot
      const key = new Date().getTime().toString()
      const component = cloneDeep({
        ...batchSlot[newIndex],
        key
      })
      this.$set(batchSlot, newIndex, component)
      this.setCurrentComponent(component)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-page-container {
  height: 100%;

  .slot {
    border: 1px dashed #999;

    .slot-item {
      &.slot-item-active {
        border: 2px solid #409EFF;
      }
    }
  }

  .slot-header {
    display: flex;
    min-width: 74px;
    height: 32px;
  }

  .slot-search {
    display: flex;
    flex-wrap: wrap;
    min-width: 1000px;
    min-height: 60px;
    line-height: 60px;
  }

  .slot-batch {
    display: flex;
    min-width: 74px;
    height: 32px;
    padding-right: 0;
    > :not(:last-child) {
      margin-right: 12px;
    }
  }
} 
</style>
