import { mapHelper } from 'common/utils'

const _ins = {
  keys: {},
  map: {},
  setOps: {}
}

// const list = [{
//   key: 'status',
//   desc: '表单状态',
//   options: [
//     {
//       text: '未发布',
//       value: 0
//     },
//     {
//       text: '已发布',
//       value: 1
//     },
//     {
//       text: '已过期',
//       value: 2
//     },
//     {
//       text: '已停用',
//       value: 3
//     }
//   ]
// }]

const init = (list) => {
  _ins.keys = {}
  _ins.map = {}
  _ins.setOps = {}
  if (Array.isArray(list) && list.length) {
    list.forEach(item => {
      const { key, options } = item
      const mapName = `${key}Map`
      const setOpsName = `set${key}Ops`
      const {
        map,
        setOps
      } = mapHelper.setMap(options)

      _ins.keys[key] = {
        mapName,
        setOpsName
      }
      _ins.map[mapName] = map
      _ins.setOps[setOpsName] = setOps
    })
  }
}

const setOps = () => {
  const ops = []
  Object.keys(_ins.keys).forEach(key => {
    const op = {
      text: key,
      value: key
    }
    ops.push(op)
  })
  return ops
}

const getItem = (key) => {
  const item = {
    map: _ins.map[_ins.keys[key].mapName],
    setOps: _ins.setOps[_ins.keys[key].setOpsName]
  }
  return item
}

export {
  init,
  setOps,
  getItem
}
