<template>
  <div class="page-design-main-container">

    <div class="operation">
      <div>
        <v-select v-model="designContent" label="当前页面：" :options="designContentOps" :width="200" @change="designContentChange" />
        <v-button v-if="designContent" class="operation-item" type="danger" text="删除" @click="designContentDelete" />
      </div>
      <div>
        <v-button class="operation-item" text="生成代码" :disabled="content.length < 1" @click="generateCode" />
        <v-button class="operation-item" text="清空" :disabled="content.length < 1" @click="clearComponent" />
        <v-button class="operation-item" text="刷新" :disabled="content.length < 1" @click="refresh" />
      </div>
    </div>

    <div class="views" :key="contentKey">
      <title-panel v-if="content.length" />
      <draggable
        class="stage"
        v-model="content"
        v-bind="{ group: { name: 'component', put: canPut, pull: false }, sort: false, ghostClass: 'ghost-column' }"
        @add="addComponent"
      >

        <template v-if="content && content.length">
          <div class="content" v-for="(item, index) in content" :key="index">
            <template v-if="item.type === 'list'">
              <list-page :item="item" />
            </template>
            <template v-if="item.type === 'form'">
              <form-page :item="item" />
            </template>
          </div>
        </template>

        <template v-else>
          <div class="empty">请先从左侧拖拽页面组件到此区域</div>
        </template>

      </draggable>
    </div>

    <el-dialog
      title="生成代码"
      width="60%"
      :visible.sync="generateCodeDialogVisible"
    >
      <code-editor :tabs="tabs" :defaultTab="defaultTab" v-if="generateCodeDialogVisible" />
      <div class="copy-code">
        <v-button text="开始构建" @click="onBuild" />
      </div>
    </el-dialog>

    <api-template />
    <map-template />
    <list-page-template />
    <form-page-template />

    <el-dialog
      custom-class="design-dialog"
      title="初始化"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      >
        <v-select style="margin-right: 20px;" v-model="centerName" :options="centerNameOps" label="所属中心" :width="200" />
        <v-input style="margin-right: 20px;" v-model="routerName" label="所属模块" :width="200" />
        <v-button text="确定" :disabled="!(centerName && routerName)" @click="init" />
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import Draggable from 'vuedraggable'
import CodeEditor from './code-editor/code-editor'
import TitlePanel from 'components/title-panel/title-panel'
import ListPage from './pages/list-page'
import FormPage from './pages/form-page'
import ApiTemplate from './template/api-template'
import MapTemplate from './template/map-template'
import ListPageTemplate from './template/list-page-template'
import FormPageTemplate from './template/form-page-template'

export default {
  name: 'page-design-main',
  components: {
    TitlePanel,
    Draggable,
    ListPage,
    FormPage,
    CodeEditor,
    ApiTemplate,
    MapTemplate,
    ListPageTemplate,
    FormPageTemplate
  },
  inject: ['setCurrentComponent'],
  data () {
    return {
      contentKey: 1,
      content: [],
      generateCodeDialogVisible: false,
      tabs: [],
      defaultTab: '',
      pageIndex: 1,
      designContent: undefined,
      designContentOps: [],
      dialogVisible: true,
      centerNameOps: [
        {
          text: '项目中心',
          value: 'project'
        },
        {
          text: '用户中心',
          value: 'user'
        },
        {
          text: '财务中心',
          value: 'financial'
        },
        {
          text: '商业中心',
          value: 'business'
        },
        {
          text: '通用中心',
          value: 'common'
        },
        {
          text: '应用中心',
          value: 'application'
        },
        {
          text: '运营中心',
          value: 'operations'
        },
        {
          text: '数据中心',
          value: 'data'
        },
        {
          text: '组织中心',
          value: 'organize'
        },
        {
          text: '物联网中心',
          value: 'iot'
        }
      ],
      centerName: 'project',
      routerName: '',
    }
  },
  created () {
    template.defaults.imports.setModels = this.setModels
    template.defaults.imports.setImport = this.setImport
    template.defaults.imports.setParams = this.setParams
    template.defaults.imports.setData = this.setData
  },
  methods: {
    init () {
      if (this.centerName && this.routerName) {
        this.dialogVisible = false
      }
    },
    canPut (a, b, c) {
      if(this.content.length === 0 && (c.className.indexOf('component-item-list') !== -1 || c.className.indexOf('component-item-form') !== -1)) {
        return true
      }
      return false
    },
    addComponent (e) {
      const { newIndex } = e
      const key = new Date().getTime().toString()
      const component = cloneDeep({
        ...this.content[newIndex],
        key
      })
      this.designContentOps.push({
        text: component.name + '-' + this.pageIndex,
        value: this.pageIndex,
        type: component.type,
        component
      })
      this.designContent = this.pageIndex
      this.pageIndex++
    },
    clearComponent () {
      this.designContent = undefined
    },
    designContentChange (value, option) {
      if (option) {
        const component = option.component
        this.$set(this.content, 0, component)
        this.setCurrentComponent({})
        this.setCurrentComponent(component)
      } else {
        this.content = []
        this.setCurrentComponent({})
      }
    },
    async designContentDelete () {
      const index = this.designContentOps.findIndex((item) => {
        return item.value === this.designContent
      })
      if (index !== -1) {
        const name = this.designContentOps[index].text
        const message = `确认删除 ${name}？`
        const result = await this.$confirm(message, {
          title: '提示'
        })
        if (!result) {
          return
        }
        this.designContentOps.splice(index, 1)
        this.clearComponent()
      }
    },
    refresh () {
      ++this.contentKey
    },
    findProp (list, key) {
      let prop = {}
      if (list && list.length) {
        prop = list.find(item => {
          return item.key === key
        }) || {}
      }
      return prop
    },
    setModels (params) {
      let str = ''
      if (params && params.length) {
        params.forEach((paramItem, paramIndex) => {
          const { type, model, props, getDefaultValue } = paramItem
          let val = getDefaultValue ? JSON.stringify(getDefaultValue(paramItem)) : ''
          if (typeof val === 'string') {
            val = val.replace(/\"/g, "'")
          }
          if (model && model.length) {
            model.forEach((item, index) => {
              const { mode, value } = item
              if (value) {
                str += '        '
                str += `${value}: ${val}`
                if (index !== model.length - 1 || paramIndex !== params.length - 1) {
                  str += ',@return'
                }
              }
            })
          }
        })
      }
      return str
    },
    setImport (params, key, path) {
      let str = ''
      if (params && params.length) {
        str += 'import { '
        params.forEach((param, index) => {
          if (key) {
            str += param[key]
          } else {
            str += param
          }
          if (index !== params.length - 1) {
            str += ', '
          }
        })
        str += ` } from '${path}'`
      }
      return str
    },
    setParams (list, modelPrefix) {
      let str = ''
      const { model, props, events } = list
      if (model && model.length) {
        model.forEach((item, index) => {
          const { mode, value, key, prefix, suffix } = item
          if (value) {
            if (mode === 1) {
              str += ` v-model="${modelPrefix ? modelPrefix : ''}${value}"`
            } else if (mode === 2) {
              str += ` ${prefix}${key}${suffix}="${modelPrefix ? modelPrefix : ''}${value}"`
            }
          }
        })
      }
      if (props && props.length) {
        props.forEach(param => {
          const { key, prefix, value, ignoreCondition } = param
          if (ignoreCondition && ignoreCondition(param)) {
            return
          }
          if (value !== '' && value !== undefined) {
            if (value === true) {
              str += ` ${key}`
            } else {
              str += ` ${prefix !== undefined ? prefix : ''}${key}="${value}"`
            }
          }
        })
      }
      Object.keys(events).forEach(key => {
        if (events[key].methodName) {
          str += ` @${key}="${events[key].methodName}${events[key].methodParams ? '(' + events[key].methodParams + ')' : ''}"`
        }
      })
      return str
    },
    upperCase (str) {
      return str ? str.replace(str[0], str[0].toUpperCase()) : ''
    },
    setData (list) {
      let str = ''
      if (list && list.length) {
        list.forEach((item, index) => {
          if (['upload', 'fileUpload'].includes(item.type)) {
            const key = this.findProp(item.props, 'action').value
            str += '      '
            str += (key + ': `${uploadURL}?module=' + item.module + '`,')
            if (index !== list.length - 1) {
              str += '@return'
            }
          }
        })
      }
      return str
    },
    getCommonApi (components) {
      const commonApi = []
      if (components && components.length) {
        const uploadIndex = components.findIndex(component => {
          return ['upload', 'fileUpload'].includes(component.type)
        })
        if (uploadIndex !== -1) {
          commonApi.push({
            name: 'uploadURL'
          })
        }
      }
      return commonApi
    },
    getOptions (pageType, components) {
      const allOptions = []
      if (components && components.length) {
        components.forEach(component => {
          if (['select', 'radio', 'checkbox'].includes(component.type)) {
            let desc = ''
            if (pageType === 'list') {
              desc = this.findProp(component.props, 'label').value
            } else if (pageType === 'form') {
              desc = component.formItemLabel
            }
            const type = component.type
            const name = component.model[0].value
            const ops = `${name}Ops`
            const map = `${name}Map`
            this.findProp(component.props, 'options').value = ops
            allOptions.push({
              type,
              desc,
              name,
              map,
              ops,
              setOps: `set${this.upperCase(ops)}`,
              options: component.options
            })
          }
        })
      }
      return allOptions
    },
    getImportComponents (components) {
      const importComponents = {
        bussiness: [],
        control: []
      }
      if (components && components.length) {
        components.forEach(component => {
          let target = undefined
          if (['radio', 'checkbox'].includes(component.type)) {
            target = importComponents.bussiness
          } else if (['ueditor', 'upload', 'fileUpload'].includes(component.type)) {
            target = importComponents.control
          }
          if (target) {
            const componentNameImport = component.componentNameImport
            const result = target.findIndex(item => {
              return item.name === componentNameImport
            })
            if (result === -1) {
              target.push({
                name: componentNameImport
              })
            }
          }
        })
      }
      return importComponents
    },
    formatTemplate (str) {
      return str.replace(/(\n[\s\t]*\r*\n)/g, '\n')
                .replace(/@newline/g, '')
                .replace(/@return/g, '\r\n')
                .replace(/<@script>/g, '<script>')
                .replace(/<@\/script>/g, '<\/script>')
    },
    initSlots (slots) {
      if (slots && slots.length) {
        slots.forEach(slot => {
          if (['upload'].includes(slot.type)) {
            const model = this.findProp(slot.model, 'imgUrls').value
            const actionProp = this.findProp(slot.props, 'action')
            actionProp.value = `${model}${actionProp.value}`
          } else if (['fileUpload'].includes(slot.type)) {
            const model = this.findProp(slot.model, 'fileList').value
            const actionProp = this.findProp(slot.props, 'action')
            actionProp.value = `${model}${actionProp.value}`
          }
        })
      }
    },
    generateCode () {
      this.tabs = []
      let allURL = []
      let allOptions = []
      const designContentOps = this.designContentOps
      if (designContentOps && designContentOps.length) {
        designContentOps.forEach((item, index) => {
          const { component, text } = item
          const { type, options } = component
          const { componentName } = options
          if (type === 'list') {
            const { isMultiSelect, headers } = options.props
            const { baseUrl, api, slots } = options
            const { headerSlot, searchSlot, operateSlot, batchSlot } = slots
            allURL.push({
              name: 'queryListURL',
              desc: '查询列表',
              baseUrl,
              api
            })
            const ops = this.getOptions('list', searchSlot)
            allOptions = allOptions.concat(ops)
            const params = {
              componentName,
              isMultiSelect,
              headers,
              headerSlot,
              searchSlot,
              operateSlot,
              batchSlot,
              ops
            }
            this.tabs.push({
              componentName,
              fileType: 'list',
              label: `${componentName}.vue`,
              name: text,
              type: 'html',
              code: this.formatTemplate(template('listPageTemplate', { params }))
            })
          } else if (type === 'form') {
            const { slots, queryBaseURL, queryURL, createBaseURL, createURL, updateBaseURL, updateURL, hasStyle } = options
            const defaultSlot = cloneDeep(slots.defaultSlot)
            this.initSlots(defaultSlot)
            const formURL = {
              urls: []
            }
            if (queryURL) {
              const url = {
                name: 'queryDetailURL',
                desc: '查询详情',
                baseUrl: queryBaseURL,
                api: queryURL
              }
              allURL.push(url)
              formURL['queryDetailURL'] = formURL.urls.length
              formURL.urls.push(url)
            }
            if (createURL) {
              const url = {
                name: 'createURL',
                desc: '新增',
                baseUrl: createBaseURL,
                api: createURL
              }
              allURL.push(url)
              formURL['createURL'] = formURL.urls.length
              formURL.urls.push(url)
            }
            if (updateURL) {
              const url = {
                name: 'updateURL',
                desc: '编辑',
                baseUrl: updateBaseURL,
                api: updateURL
              }
              allURL.push(url)
              formURL['updateURL'] = formURL.urls.length
              formURL.urls.push(url)
            }
            const ops = this.getOptions('form', defaultSlot)
            const importComponents = this.getImportComponents(defaultSlot)
            importComponents.bussiness = [
              {
                name: 'Col2Detail'
              },
              {
                name: 'Col2Block'
              }
            ].concat(importComponents.bussiness)
            allOptions = allOptions.concat(ops)
            const commonApi = this.getCommonApi(defaultSlot)
            const params = {
              componentName,
              defaultSlot,
              hasStyle,
              formURL,
              ops,
              commonApi,
              importComponents
            }
            this.tabs.push({
              componentName,
              fileType: 'form',
              label: `${componentName}.vue`,
              name: text,
              type: 'html',
              code: this.formatTemplate(template('formPageTemplate', { params }))
            })
          }
        })
      }
      if (allOptions && allOptions.length) {
        const params = {
          allOptions
        }
        this.tabs.unshift({
          label: 'map.js',
          name: 'map',
          type: 'javascript',
          code: this.formatTemplate(template('mapTemplate', { params }))
        })
      }
      if (allURL && allURL.length) {
        const params = {
          allURL
        }
        this.tabs.unshift({
          label: 'api.js',
          type: 'javascript',
          name: 'api',
          code: this.formatTemplate(template('apiTemplate', { params }))
        })
      }
      const designContent = this.designContent
      const option = this.designContentOps.find(item => {
        return item.value === designContent
      })
      if (option) {
        this.defaultTab = option.text
      }
      this.generateCodeDialogVisible = true
    },
    async onBuild () {
      const list = this.tabs.find(tab => {
        return tab.fileType === 'list'
      })
      const form = this.tabs.find(tab => {
        return tab.fileType === 'form'
      })
      let name
      if (list) {
        name = list.componentName.replace(list.componentName[0], list.componentName[0].toLowerCase());
      } else if (form) {
        name = form.componentName.replace(form.componentName[0], form.componentName[0].toLowerCase());
      }
      const path = `#/home?login=1&url=${name}`
      const params = {
        centerName: this.centerName,
        routerName: this.routerName,
        files: this.tabs
      }
      const res = await this.$axios.post('/pageDesign', params)
      if (typeof res === 'string') {
        this.$message.success(res)
        window.open(path)
      } else {
        this.$message.error('请先在项目根目录下运行命令：npm run code')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-design-main-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .operation {
    display: flex;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    text-align: right;
    padding: 0 10px;
    border-left: 1px dashed #ccc;
    border-right: 1px dashed #ccc;

    .operation-item {
      margin: 0 5px;
    }
  }

  .views {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    border: 1px dashed #ccc;

    .stage {
      flex: 1;

      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        font-size: 18px;
        color: #999;
      }

      .content {
        height: 100%;
      }
    }
  }

  .copy-code {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #E4E7ED;
    text-align: center;
  }

}
</style>
