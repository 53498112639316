<template>
  <div class="page-design-components-container">

      <div class="component-group">

        <div
          class="component-group-item"
          v-for="(componentGroupItem, componentGroupIndex) in componentGroup"
          :key="componentGroupIndex"
        >

          <div class="component-group-item-title">{{ componentGroupItem.title }}</div>

          <draggable
            class="component-list"
            tag="ul"
            :list="componentGroupItem.list"
            v-bind="draggableParams"
          >

            <li class="component-item"
              :class="'component-item-' + componentItem.type"
              :data-type="componentItem.type"
              v-for="(componentItem, componentIndex) in componentGroupItem.list"
              :key="componentIndex"
            >
              {{ componentItem.name }}
            </li>

          </draggable>

        </div>

      </div>

  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { cloneDeep } from 'lodash'
import { componentGroup } from './common/setting'

export default {
  name: 'page-design-components',
  components: {
    Draggable
  },
  data () {
    return {
      componentGroup: [],
      draggableParams: {
        group: {
          name: 'component',
          pull: 'clone',
          put: false
        },
        sort: false,
        ghostClass: 'ghost'
      }
    }
  },
  created () {
    this.componentGroup = cloneDeep(componentGroup)
    // const _componentGroup = cloneDeep(componentGroup)
    // if (Array.isArray(_componentGroup) && _componentGroup.length) {
    //   _componentGroup.forEach(componentGroupItem => {
    //     const { type, list } = componentGroupItem
    //     if (Array.isArray(list) && list.length) {
    //       list.forEach(item => {
    //         item['parentType'] = type
    //         if (item.type === 'list') {
    //           Object.keys(API_CONFIG).forEach(key => {
    //             const option = {
    //               text: key,
    //               value: key
    //             }
    //             this.baseUrlOps.push(option)
    //           })
    //           item.options.baseUrl = 'controlBaseURL'
    //         }
    //       })
    //     }
    //   })
    //   this.componentGroup = _componentGroup
    // }
  }
}
</script>

<style scoped lang="scss">
.page-design-components-container {

  .component-group {
    min-width: 288px;
    max-width: 288px;
    height: 100%;
    padding: 10px;
    background: #FFF;

    .component-group-item {
      margin-bottom: 10px;

    .component-group-item-title {
        font-size: 16px;
        padding-bottom: 10px;
      } 

      .component-list {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0;

        .component-item {
          width: 118px;
          margin: 2px;
          padding: 10px;
          background: #f4f4f4;
          cursor: grab;
        }
      }
    }
  }
}
</style>
