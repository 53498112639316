const findProp = (list, key) => {
  let prop = {}
  if (list && list.length) {
    prop = list.find(item => {
      return item.key === key
    }) || {}
  }
  return prop
}

export {
  findProp
}
