var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-design-container" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("page-design-components"),
        _c("page-design-main", { staticClass: "page-design-main" }),
        _c("page-design-config", {
          ref: "pageDesignConfig",
          attrs: {
            currentPage: _vm.currentPage,
            currentComponent: _vm.currentComponent,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }