import { render, staticRenderFns } from "./api-template.vue?vue&type=template&id=347ef7cf"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/智慧物业-前端-新服务器/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('347ef7cf')) {
      api.createRecord('347ef7cf', component.options)
    } else {
      api.reload('347ef7cf', component.options)
    }
    module.hot.accept("./api-template.vue?vue&type=template&id=347ef7cf", function () {
      api.rerender('347ef7cf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page-design/template/api-template.vue"
export default component.exports