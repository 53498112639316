var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-design-components-container" }, [
    _c(
      "div",
      { staticClass: "component-group" },
      _vm._l(
        _vm.componentGroup,
        function (componentGroupItem, componentGroupIndex) {
          return _c(
            "div",
            { key: componentGroupIndex, staticClass: "component-group-item" },
            [
              _c("div", { staticClass: "component-group-item-title" }, [
                _vm._v(_vm._s(componentGroupItem.title)),
              ]),
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "component-list",
                    attrs: { tag: "ul", list: componentGroupItem.list },
                  },
                  "draggable",
                  _vm.draggableParams,
                  false
                ),
                _vm._l(
                  componentGroupItem.list,
                  function (componentItem, componentIndex) {
                    return _c(
                      "li",
                      {
                        key: componentIndex,
                        staticClass: "component-item",
                        class: "component-item-" + componentItem.type,
                        attrs: { "data-type": componentItem.type },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(componentItem.name) +
                            "\n          "
                        ),
                      ]
                    )
                  }
                ),
                0
              ),
            ],
            1
          )
        }
      ),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }