<template>
  <div>

    <template v-if="component.type === 'input'">
      <v-input
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-else-if="component.type === 'select'">
      <v-select
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-else-if="component.type === 'textarea'">
      <v-textarea
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-else-if="['radio', 'checkbox'].includes(component.type)">
      <checkbox-plus
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-if="component.type === 'inputNumber'">
      <v-input-number
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-else-if="component.type === 'ueditor'">
      <v-ueditor
        v-model="model"
        v-bind="getProps(component)"
      />
    </template>

    <template v-else-if="['upload', 'fileUpload'].includes(component.type)">
      <v-uploader
        v-bind="getProps(component)"
      />
    </template>

  </div>
</template>

<script>
import { vUeditor, vUploader } from 'components/control'
import { CheckboxPlus } from 'components/bussiness'
import { uploadURL } from 'common/api'

export default {
  components: {
    vUploader,
    vUeditor,
    CheckboxPlus
  },
  props: {
    component: Object
  },
  data () {
    return {
      model: this.component.getDefaultValue ? this.component.getDefaultValue(this.component) : '',
      uploadURL: `${uploadURL}?module=icon`
    }
  },
  methods: {
    getProps (component) {
      const props = {}
      if (component && component.props && component.props.length) {
        component.props.forEach(prop => {
          props[prop.key] = prop.value
        })
      }
      if (['select', 'radio', 'checkbox'].includes(component.type)) {
        if (['radio', 'checkbox'].includes(component.type)) {
          if (component.options && component.options.length) {
            component.options.forEach(option => {
              option['label'] = option['text']
              option['disabled'] = false
            })
          }
        }
        props['options'] = component.options
      } else if (['upload', 'fileUpload'].includes(component.type)) {
        props['action'] = this.uploadURL
      }
      return props
    }
  }
}
</script>
