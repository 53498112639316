<template>
  <div class="page-design-config-container">

    <div class="title">

      <div
        class="title-item"
        v-for="(item, index) in tabs"
        :key="item.id"
        :class="{ 'title-item-active': active == item.id }"
        @click="setActive(index)"
      >
        {{ item.name }}
      </div>

    </div>

    <div class="component-config-list">

      <template v-if="active === 0">

        <template v-if="currentPage && Object.keys(currentPage).length">

          <template v-if="currentPage.type === 'list'">

            <div class="component-config-item">
              <div class="component-config-item-name">组件名称</div>
              <v-input v-model="currentPage.options.componentName" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">查询接口</div>
              <v-select class="component-config-control" v-model="currentPage.options.baseUrl" :options="baseUrlOps" />
              <v-input v-model="currentPage.options.api" placeholder="请输入接口地址" :maxlength="200" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">列表项</div>
              <div class="component-config-item-options" v-for="(item, index) in currentPage.options.props.headers" :key="index">
                <v-input v-model="item.label" />
                <v-input v-model="item.prop" />
                <v-button type="text" text="配置" @click="configHeader(item)" />
                <v-button type="text" text="删除" @click="removeHeader(index)" />
              </div>
              <v-button type="text" text="添加" @click="addHeader" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">操作按钮</div>
              <div class="component-config-item-options" v-for="(item, index) in currentPage.options.slots.operateSlot" :key="index">
                <v-input v-model="findProp(item.props, 'text').value" />
                <v-button type="text" text="删除" @click="removeOperation(index)" />
              </div>
              <v-button type="text" text="添加" @click="addOperation" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">显示多选列</div>
              <el-switch v-model="currentPage.options.props.isMultiSelect" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">显示操作列</div>
              <el-switch v-model="currentPage.options.props.hasOperateColumn" />
            </div>

            <el-dialog custom-class="config-header-dialog"
              title="配置列表项"
              :visible.sync="configHeaderDialogVisible"
            >
              <div class="config-header-dialog-content" v-if="configHeaderDialogVisible">
                <v-select v-model="map" label="选择映射关系" :options="mapOps" @change="mapChange" clearable />
              </div>
            </el-dialog>

          </template>
          
          <template v-else-if="currentPage.type === 'form'">
            
            <div class="component-config-item">
              <div class="component-config-item-name">组件名称</div>
              <v-input v-model="currentPage.options.componentName" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">详情查询接口</div>
              <v-select class="component-config-control" v-model="currentPage.options.queryBaseURL" :options="baseUrlOps" />
              <v-input v-model="currentPage.options.queryURL" placeholder="请输入接口地址" :maxlength="200" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">新增接口</div>
              <v-select class="component-config-control" v-model="currentPage.options.createBaseURL" :options="baseUrlOps" />
              <v-input v-model="currentPage.options.createURL" placeholder="请输入接口地址" :maxlength="200" />
            </div>

            <div class="component-config-item">
              <div class="component-config-item-name">编辑接口</div>
              <v-select class="component-config-control" v-model="currentPage.options.updateBaseURL" :options="baseUrlOps" />
              <v-input v-model="currentPage.options.updateURL" placeholder="请输入接口地址" :maxlength="200" />
            </div>

          </template>

          <!-- style标签 start -->

          <template v-if="Object.keys(currentPage.options).includes('hasStyle')">

            <div class="component-config-item">
              <div class="component-config-item-name">style标签</div>
              <el-switch v-model="currentPage.options.hasStyle" />
            </div>

          </template>

          <!-- style标签 end -->

        </template>

        <template v-else>

          <div class="empty">暂无页面属性</div>

        </template>

      </template>

      <template v-else-if="active === 1">

        <template v-if="currentComponent && Object.keys(currentComponent).length">

          <!-- 双向绑定 start -->

          <template v-if="Object.keys(currentComponent).includes('model')">

            <div class="component-config-item" v-for="(item, index) in currentComponent.model" :key="index">
              <div class="component-config-item-name">{{ item.name }}</div>
              <v-input v-model="item.value" />
            </div>

          </template>

          <!-- 双向绑定 end -->

          <!-- 表单控件标题 start -->

          <template v-if="currentPage.type === 'form' && Object.keys(currentComponent).includes('formItemLabel')">

            <div class="component-config-item">
              <div class="component-config-item-name">字段描述</div>
              <v-input v-model="currentComponent.formItemLabel" />
            </div>

          </template>

          <!-- 表单控件标题 end -->

          <template v-for="item in currentComponent.props">

            <template v-if="item.show">

              <div class="component-config-item" :key="item.key">
                <div class="component-config-item-name">{{ item.name }}</div>
                <template v-if="item.type === 'number'">
                  <v-input-number v-model="item.value" :controls="true" :min="item.min" :max="item.max" />
                </template>
                <template v-else-if="item.type === 'select'">
                  <v-select v-model="item.value" :options="item.options" />
                </template>
                <template v-else-if="item.type === 'boolean'">
                  <el-switch v-model="item.value" />
                </template>
                <template v-else>
                  <v-input v-model="item.value" />
                </template>
              </div>

            </template>

          </template>

          <template v-if="currentComponent.type === 'button'">

            <div class="component-config-item">
              <div class="component-config-item-name">点击事件</div>
              <v-input placeholder="方法名" v-model="currentComponent.events.click.methodName" />
              <v-input placeholder="参数" v-model="currentComponent.events.click.methodParams" />
            </div>

          </template>

          <template v-else-if="['upload', 'fileUpload'].includes(currentComponent.type)">

            <div class="component-config-item">
              <div class="component-config-item-name">模块</div>
              <v-input v-model="currentComponent.module" />
            </div>

          </template>

          <!-- 选项 start -->

          <template v-if="Object.keys(currentComponent).includes('options')">

            <div class="component-config-item">
              <div class="component-config-item-name">选项</div>
              <div class="component-config-item-options" v-for="(item, index) in currentComponent.options" :key="index">
                <v-input v-model="item.text" />
                <v-input v-model="item.value" />
                <v-button type="text" text="删除" @click="removeOptions(index)" />
              </div>
              <v-button type="text" text="添加" @click="addOptions" />
            </div>

          </template>

          <!-- 选项 end -->

          <!-- 表单控件校验规则 start -->

          <template v-if="currentPage.type === 'form' && Object.keys(currentComponent).includes('rule')">

            <div class="component-config-item">
              <div class="component-config-item-name">是否必填</div>
              <el-switch v-model="currentComponent.rule.required" />
            </div>

            <div v-show="currentComponent.rule.required" >

              <div class="component-config-item">
                <div class="component-config-item-name">提示信息</div>
                <v-input v-model="currentComponent.rule.message" />
              </div>

              <div class="component-config-item">
                <div class="component-config-item-name">触发方式</div>
                <v-input v-model="currentComponent.rule.trigger" />
              </div>

            </div>

          </template>

          <!-- 表单控件校验规则 end -->

        </template>

        <template v-else>

          <div class="empty">暂无组件属性</div>

        </template>

      </template>

    </div>

  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { Switch } from 'element-ui'
import { setOps, getItem } from './common/handleMap'
import { operationButton } from './common/setting'

export default {
  name: 'page-design-config',
  inject: ['baseUrlOps'],
  components: {
    'el-switch': Switch
  },
  props: {
    currentPage: Object,
    currentComponent: Object
  },
  data () {
    return {
      tabs: [
        {
          name: '页面属性',
          id: 0
        },
        {
          name: '组件属性',
          id: 1
        }
      ],
      active: 0,
      currentHeaderMap: {},
      map: undefined,
      mapOps: setOps(),
      configHeaderDialogVisible: false
    }
  },
  methods: {
    setActive (active) {
      this.active = active
    },
    configHeader (item) {
      this.currentHeaderMap = item
      this.configHeaderDialogVisible = true
    },
    addHeader () {
      this.currentPage.options.props.headers.push({
        prop: '',
        label: ''
      })
    },
    removeHeader (index) {
      this.currentPage.options.props.headers.splice(index, 1)
    },
    addOperation () {
      this.currentPage.options.slots.operateSlot.push(cloneDeep(operationButton))
    },
    removeOperation (index) {
      this.currentPage.options.slots.operateSlot.splice(index, 1)
    },
    addOptions () {
      this.currentComponent.options.push({
        text: '',
        value: ''
      })
    },
    removeOptions (index) {
      this.currentComponent.options.splice(index, 1)
    },
    mapChange (val) {
      if (val) {
        const item = getItem(val)
        this.currentHeaderMap['formatter'] = (row) => {
          return item.map[row[val]]
        }
      } else {
        this.currentHeaderMap['formatter'] = undefined
      }
    },
    findProp (list, key) {
      let prop = {}
      if (list && list.length) {
        prop = list.find(item => {
          return item.key === key
        }) || {}
      }
      return prop
    }
  }
}
</script>

<style scoped lang="scss">
.page-design-config-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  background: #FFF;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-bottom: 1px dashed #ccc;

    .title-item {
      width: 50%;
      cursor: pointer;

      &.title-item-active {
        color: #409eff;
        border-bottom: 1px solid #409eff;
      }
    }
  }

  .component-config-list {
    flex: 1;
    overflow: auto;

    .component-config-item {
      padding: 10px;

      .component-config-control {
        margin-bottom: 6px;
      }

      .component-config-item-name {
        margin-bottom: 10px;
      }

      .component-config-item-options {
        display: flex;
        align-items: center;
      }

      ::v-deep {
        .v-input-container {
          width: 100%;

          .el-input {
            width: 100% !important;
          }
        }
        .v-select-container {
          width: 100%;

          .el-select {
            width: 100% !important;
          }
        }
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    color: #999;
  }
}
</style>
