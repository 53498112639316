<template>
  <div class="code-editor-container">
    <el-tabs v-model="activeTab" type="card">
      <el-tab-pane
        v-for="(tabItem, tabIndex) in tabs"
        :key="tabIndex"
        :label="tabItem.label"
        :name="tabItem.name"
      >
        <div class="code-editor" :id="'code_editor_' + tabIndex" v-show="visible">
          {{ tabItem.code }}
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
const resourceURL = 'https://cdnjs.cloudflare.com/ajax/libs/ace/1.4.7/ace.js'

export default {
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    defaultTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      activeTab: '',
      code: ''
    }
  },
  mounted () {
    const _this = this
    _this.activeTab = _this.defaultTab || _this.tabs[0].name
    _this.$nextTick(() => {
      if (window.ace) {
        _this.init()
      } else {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = resourceURL
        document.body.appendChild(script)
        script.onload = () => {
          _this.init()
        }
      }
    })
  },
  methods: {
    init () {
      this.tabs.forEach((tabItem, tabIndex) => {
        const mode = `ace/mode/${tabItem.type}`
        const id = 'code_editor_' + tabIndex
        const editor = window.ace.edit(id)
        editor.session.setMode(mode)
      })
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
.code-editor-container {
  height: 550px;
  .code-editor {
    height: 500px;
  }
}
</style>
