<template>
  <div class="page-design-container">

    <div class="header">

      <div>{{ title }}</div>

    </div>

    <div class="content">

      <page-design-components />

      <page-design-main class="page-design-main" />

      <page-design-config ref="pageDesignConfig" :currentPage="currentPage" :currentComponent="currentComponent" />

    </div>

  </div>
</template>

<script>
import PageDesignComponents from './page-design-components'
import PageDesignMain from './page-design-main'
import PageDesignConfig from './page-design-config'

export default {
  name: 'page-design',
  components: {
    PageDesignComponents,
    PageDesignMain,
    PageDesignConfig
  },
  provide () {
    return {
      baseUrlOps: this.baseUrlOps,
      setCurrentComponent: this.setCurrentComponent,
      getCurrentComponent: this.getCurrentComponent
    }
  },
  data () {
    return {
      title: '页面设计器',
      currentPage: undefined,
      currentComponent: undefined,
      baseUrlOps: []
    }
  },
  created () {
    this.getToken()
    this.$setBreadcrumb({
      reset: true,
      breadcrumb: this.title
    })
    this.initBaseUrlOps()
  },
  methods: {
    getToken () {
      const token = this.$axios.defaults.headers.token
      if (!token) {
        this.$router.push({
          name: 'login'
        })
      }
    },
    initBaseUrlOps () {
      Object.keys(API_CONFIG).forEach(key => {
        const option = {
          text: key,
          value: key
        }
        this.baseUrlOps.push(option)
      })
    },
    setCurrentComponent (component) {
      const { type } = component
      if (type) {
        if (['list', 'form'].includes(type)) {
          this.currentPage = component
          this.$refs.pageDesignConfig.setActive(0)
        } else {
          this.currentComponent = component
          this.$refs.pageDesignConfig.setActive(1)
        }
      } else {
        this.currentPage = component
        this.currentComponent = component
      }
    },
    getCurrentComponent () {
      return this.currentComponent
    }
  }
}
</script>

<style lang="scss">
.page-design-container {
  .ghost-row {
    content: '';
    font-size: 0;
    outline-width: 0;
    box-sizing: border-box;
    background: #409EFF;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 2px;
    height: 100%;
  }
  .ghost-column {
    content: '';
    font-size: 0;
    outline-width: 0;
    box-sizing: border-box;
    background: #409EFF;
    overflow: hidden;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 2px;
  }
}
</style>

<style scoped lang="scss">
.page-design-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e3f0ff;
    padding: 10px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    // background: #FAFAFA;

    .page-design-main {
      flex: 1;
      background: #FFF;
    }
  }
}
</style>
