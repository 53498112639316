var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-page-container" },
    [
      _c(
        "form-panel",
        {
          scopedSlots: _vm._u([
            {
              key: "headerSlot",
              fn: function () {
                return [_c("div")]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "slot slot-default",
                on: { add: _vm.defaultSlotAdd },
                model: {
                  value: _vm.item.options.slots.defaultSlot,
                  callback: function ($$v) {
                    _vm.$set(_vm.item.options.slots, "defaultSlot", $$v)
                  },
                  expression: "item.options.slots.defaultSlot",
                },
              },
              "draggable",
              {
                group: { name: "component", put: _vm.canPutDefaultSlot },
                ghostClass: "ghost-column",
                animation: 200,
              },
              false
            ),
            [
              _vm._l(_vm.item.options.slots.defaultSlot, function (component) {
                return [
                  _c(
                    "div",
                    {
                      key: component.key,
                      staticClass: "slot-item",
                      class: {
                        "slot-item-active": _vm.activeKey == component.key,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clickHandler(component)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: component.formItemLabel } },
                        [
                          _c("generate-component", {
                            attrs: { component: component },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }