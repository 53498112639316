var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.component.type === "input"
        ? [
            _c(
              "v-input",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "v-input",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : _vm.component.type === "select"
        ? [
            _c(
              "v-select",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "v-select",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : _vm.component.type === "textarea"
        ? [
            _c(
              "v-textarea",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "v-textarea",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : ["radio", "checkbox"].includes(_vm.component.type)
        ? [
            _c(
              "checkbox-plus",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "checkbox-plus",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : _vm._e(),
      _vm.component.type === "inputNumber"
        ? [
            _c(
              "v-input-number",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "v-input-number",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : _vm.component.type === "ueditor"
        ? [
            _c(
              "v-ueditor",
              _vm._b(
                {
                  model: {
                    value: _vm.model,
                    callback: function ($$v) {
                      _vm.model = $$v
                    },
                    expression: "model",
                  },
                },
                "v-ueditor",
                _vm.getProps(_vm.component),
                false
              )
            ),
          ]
        : ["upload", "fileUpload"].includes(_vm.component.type)
        ? [
            _c(
              "v-uploader",
              _vm._b({}, "v-uploader", _vm.getProps(_vm.component), false)
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }